/* You can add global styles to this file, and also import other style files */

$primaryColor: #597a89;
$secondaryColor: #e0e0e0;
$miniColor: #00A4E8;
$infoColor: #00A4E8;

$theme-colors: ( "primary": $primaryColor,
    "secondary": $secondaryColor,
    "success": #198754,
    "info": $infoColor,
    "danger": #dc3545,
    "warning": #ffc107,
    "light": #f8f9fa,
    "dark": #fff );

$btn-border-radius: 0;
$form-check-input-checked-bg-color: $infoColor;
$form-check-input-checked-border-color: $infoColor;

$dropdown-item-padding-x: 1.5rem;
$dropdown-item-padding-y: 0.25rem;


/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';
@import '@angular/cdk/overlay-prebuilt.css';
@import "@palliativ-core/styles.scss";
// regular style toast
@import 'ngx-toastr/toastr';

// boostrap 5
@import 'ngx-toastr/toastr-bs5-alert';



