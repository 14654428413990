

@for $i from 1 through 20 {
    $w: calc($i * 5);

    .w-#{$w} {
      width: #{$w}#{"%"} !important;
    }
  }

@font-face {
    font-family: MavenPro;
    src: url("assets/font/MavenPro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: MavenPro;
    src: url("assets/font/MavenPro-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: Palanquin;
    src: url("assets/font/Palanquin-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Palanquin;
    src: url("assets/font/Palanquin-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}


html,
body {
  height: 100% !important;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: MavenPro, sans-serif;
}


h1, h2, h3, h4, h5, h6 {
  font-family: Palanquin, sans-serif;
  font-weight: bold;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}


nav {
  background-color: $primaryColor !important;
}

nav a {
  color: white !important;
}

nav a:hover {
  text-decoration: none !important;
  background-color: lighten($primaryColor, 10%);
}

.dropdown-item:hover {
    background-color: lighten($primaryColor, 40%);
}
.dropdown-toggle:hover {
    color: $primaryColor;
    background-color: lighten($primaryColor, 40%);
}
.dropdown-toggle:active {
    color: $primaryColor !important;
    background-color: lighten($primaryColor, 40%) !important;
}

section.container {
  margin-bottom: 1em;
}

footer {
    margin-top: auto;
    background-color: $primaryColor !important;
    color: white !important;
    font-size: .9em;
    padding-top: 1rem;
}

footer a, footer p {
  color: white !important;
}


.otimo-logo {
  margin: 10px;
  height: 20px
}

.navbar-toggler {
  color: #FFF;
}


i.icon-backspace-outline {
  content: '';
  display: inline-block;
  vertical-align: bottom;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12L19,15.59M22,3A2,2 0 0,1 24,5V19A2,2 0 0,1 22,21H7C6.31,21 5.77,20.64 5.41,20.11L0,12L5.41,3.88C5.77,3.35 6.31,3 7,3H22M22,5H7L2.28,12L7,19H22V5Z" /></svg>');
  background-repeat: no-repeat;
}


i.icon-calendar-outline {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 18px;
    height: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M19,8H5V6h14V8z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18 h-2v-2h2V18z" /></svg>');
    background-repeat: no-repeat;
}


i.icon-search-outline {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 18px;
    height: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>');
    background-repeat: no-repeat;
}
.nav-item:hover {
    cursor: pointer;
}

.bg-white a {
    color: $dark !important;
}

.bg-white a:hover {
    color: $primaryColor !important;
    background: none;
}

.dropdown .link-light:hover {
    background-color: lighten($primaryColor, 10%);
}
